


















































































































import { Component, Prop, Mixins } from 'vue-property-decorator'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import axios from 'axios'
import snakeCaseKeys from 'snakecase-keys'
import camelCaseKeys from 'camelcase-keys'
import { mask } from 'vue-the-mask'
import TrashCan16 from '@carbon/icons-vue/es/trash-can/16'
import ArrowUp16 from '@carbon/icons-vue/es/arrow--up/16'
import ArrowDown16 from '@carbon/icons-vue/es/arrow--down/16'

import NotifyOnFailed from '@/partials/forms/NotifyOnFailed.vue'
import ValidatorConfigMixin from '@/mixins/validatorConfigMixin'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'
import RichTextEditor from '@/partials/forms/components/RichTextEditor.vue'

@Component({
  directives: { mask },
  components: {
    ArrowDown16,
    ArrowUp16,
    TrashCan16,
    RichTextEditor,
    NotifyOnFailed,
    PasswordConfirmField,
    ValidationProvider,
    ValidationObserver
  }
})

export default class ScheduleQuestionForm extends Mixins(ValidatorConfigMixin, FlashMessageMixin) {
  @Prop({ required: true, type: String }) title!: string
  @Prop({ required: true, type: String }) type!: 'create' | 'edit'
  @Prop({ required: true, type: String }) eventId!: string
  @Prop({ type: String }) scheduleQuestionId!: string

  done = true
  form: Record<string, any> = {
    status: '',
    title: '',
    body: '',
    answer: '',
    eventId: '',
    eventScheduleId: '',
    eventSubscriptionId: '',
    confirm: '',
    errors: {
      status: '',
      title: '',
      body: '',
      answer: '',
      eventId: '',
      eventScheduleId: '',
      eventSubscriptionId: '',
      confirm: ''
    }
  }

  schedules: Array<{ id: string; [key: string]: any }> = []
  subscriptions: Array<{ id: string; [key: string]: any }> = []

  statuses = [
    {
      name: 'Recebida',
      value: 'received'
    },
    {
      name: 'Aguardando resposta',
      value: 'accepted'
    },
    {
      name: 'Respondida',
      value: 'answered'
    },
    {
      name: 'Rejeitada',
      value: 'rejected'
    }
  ]

  created () {
    this.form.eventId = this.eventId

    axios.get(`/event/${this.eventId}/schedules/dropdown`)
      .then(response => {
        this.schedules = response.data.data
      })

    axios.get(`/event/${this.eventId}/subscriptions/dropdown`)
      .then(response => {
        this.subscriptions = response.data.data
      })

    if (process.env.NODE_ENV === 'development' && this.type !== 'edit') {
      Object.assign(this.form, {
        status: 'received',
        title: 'Pergunta',
        body: 'Texto da pergunta'
      })
    }

    if (this.type === 'edit') {
      axios.get(`/event/${this.eventId}/schedule-questions/${this.scheduleQuestionId}`)
        .then(response => {
          const scheduleQuestion = camelCaseKeys(response.data.data, { deep: true })
          Object.keys(scheduleQuestion).forEach((key: string) => {
            this.form[key] = key in this.form ? scheduleQuestion[key] : this.form[key]
          })
          this.form.eventScheduleId = scheduleQuestion.schedule.id
          this.form.eventSubscriptionId = scheduleQuestion.subscription.id
        })
    }
  }

  submit () {
    this.done = false
    const verb = this.type === 'edit' ? 'put' : 'post'
    const route = this.type === 'edit'
      ? `/event/${this.eventId}/schedule-questions/${this.scheduleQuestionId}`
      : `/event/${this.eventId}/schedule-questions`

    axios[verb](route, snakeCaseKeys(this.form))
      .then(() => {
        this.redirectOnSuccess()
      })
      .catch(err => this.handleSubmitError(err))
      .finally(() => { this.done = true })
  }

  redirectOnSuccess () {
    const successVerb = this.type === 'edit' ? 'editada' : 'cadastrada'

    const flashMessage = {
      message: `A Pergunta foi ${successVerb} com sucesso.`,
      kind: 'success'
    }

    this.$router.push({
      name: 'EventScheduleQuestions',
      params: {
        id: this.eventId
      }
    }, () => this.setFlashMessage(flashMessage))
  }

  handleSubmitError (err: any) {
    const response = err.response

    const errors = camelCaseKeys(response.data.errors, { deep: true })
    if (errors) {
      Object.entries(errors).map(([key, value]: [string, any]) => {
        this.form.errors[key] = value[0]
      })
    }
  }

  resetErrors () {
    this.form.errors = {
      eventId: '',
      confirm: ''
    }
  }
}
